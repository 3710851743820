
import React, { useState } from 'react';
import styled from "styled-components";
import { AiOutlineUp } from 'react-icons/ai';
import { DiWindows } from 'react-icons/di';
const ButtonUP = styled.div`
    position:fixed;
    bottom:20px;
    right:20px;
    background-color:black;
    border-radius:50%;
    height:50px;
    width:50px;
    display:flex;
    justify-content:center;
    align-items:center;
        a {
            color:white;
            font-size:1.5em;
        }
    :hover {
        cursor: pointer;
        background-color: #222222;
    }
`
function LoggingButton(props:any) {
    const [flagShow, setFlag] = useState(false);
    window.addEventListener("scroll", function () {
        (window.pageYOffset > 100) ? setFlag(true) : setFlag(false);
    });
    return  (
        (flagShow)
        ? <ButtonUP> <a href="#home"><AiOutlineUp/></a></ButtonUP>
        : <></>
    )
}

export default LoggingButton;
