import styled from "styled-components";

const Section = styled.div`
    margin: 0.5em;
    padding-left: 20px;
    border-left: 8px solid #C70039;
    height: 40;
    display: flex;
    align-items: center;
    font-size: 1.7em;
    @media (max-width: 768px){
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @media (max-width: 425px){
        font-size: 1.4em;
        margin-top: 20px;
        margin-bottom: 20px;
    }
`
export default Section;