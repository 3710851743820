import Section from './style_contact';
const Contact = () => {
    return (
        <Section>
            <div>
                <h2>Let's work together.</h2>
            </div>
            <br />
            <p>jjsucre@gmail.com</p>
            <br />
            <p>@jjsucre</p>
        </Section>
    )
}

export default Contact;