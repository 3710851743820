    import styled from 'styled-components'
import { TiSocialLinkedin } from 'react-icons/ti';
import { TiSocialTwitter } from 'react-icons/ti';
import { TiSocialGithub } from 'react-icons/ti';

const Redes = styled.div`
        flex-grow: 1;
        font-size: 1.3em;
        div {
            width: 150px;
            display: inline-block;
            transition: ease-in-out 0.3s;
            font-size: 1.3em;
            height: 100px;
        }
        b {
            position: relative;
            padding-left: 20px;
            transition: ease-in-out 0.2s;
            top: 0px;
        }
        a {
            color: #fff;
            text-decoration: none;
        }
        b:hover {
            cursor: pointer;
            top: -5px;
        }
        @media (max-width: 450px) {
            div {
                width: 100%;
            }
        }
`
const Nav = styled.nav`
    position: absolute;
    top: 10px;
    padding: 50px;
    height: 70px;
    width: 100%;
    color: white;
    display: flex;
    span {
        color:white;
        flex-grow: 1;
        font-size: 1.3em;
    }
    .menu ul {
        @media (max-width: 768px){
            display:none;
        };
    }
    .menu ul li {
        @media (max-width: 768px){
            display:none;
        };
    }
    .menu ul li {
        display: inline-block;
        //transition: ease-in-out 0.3s;
        margin-left: 20px;
        //border-radius:25px;
        font-size: 1.5em;
        font-weight: 400;
        padding-left:15px;
        padding-right:15px;
        padding-bottom:3px;
        a {
            color: white;
            text-decoration: none;
        }
    }

    .menu li:hover {
        cursor: pointer;
        //background-color: #C70039;
        border-bottom: 3px solid #C70039;
    }
    @media (max-width: 768px){
            padding: 0px;
    };
`
const Header = () => {
    return (
        <Nav id="home">
            <Redes>
                <div>
                    <b><a href="https://twitter.com/jjsucre" target="_blank"><TiSocialLinkedin/></a></b>
                    <b><a href="https://www.linkedin.com/in/joseph-sucre-5b7775205/" target="_blank"><TiSocialTwitter/></a></b>
                    <b><a href="https://github.com/jjsucre" target="_blank"><TiSocialGithub/></a></b>
                </div>
            </Redes>
            <div className ="menu">
                <ul>
                    <li>Home</li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#skills">Skills</a></li>
                    <li><a href="#portfolio">Porfolio</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </div>
        </Nav>
    )
}

export default Header;