import styled from "styled-components";
import { TextLoop } from "react-text-loop-next";
interface bannerTitleProps  {
    title: string;
    sub_title: string;
}
const Section = styled.section`
 -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    top: 0px;
    height: 100vh;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-content: center;
    margin: 0 auto;
    .container {
        position: relative;
        float: left;
        left:50px;
        width: 800px;
        height: 250px;
        line-height: 100px;
    }
    @media (max-width:1024px) {
        .container {
            line-height: 80px;
            font-size: 1.2rem;
        }
    }
    @media (max-width:768px) {
        .container {
            width: 90vw;
            line-height: 80px;
            font-size: 1.2rem;
        }
    }
    @media (max-width:425px) {
        .container {
            left:10px;
            width: 90vw;
            line-height: 40px;
            font-size: .5rem;
        }

    }
`
const Title = styled.div`
    font-size: 3em;
    @media (max-width:1024px) {
        font-size: 4rem;
    }
    @media (max-width:768px) {
        font-size: 4rem;
    }
    @media (max-width:425px) {
        font-size: 2rem;
    }
    h1 {
        font-weight: 900;
        font-family: "Montserrat", sans-serif;
        font-weight: 900;
        color:white;
        @media (max-width:768px) {
        }
        @media (max-width:425px) {
            font-size: 1.7em;
        }
    }
`
const SubTitle = styled.div`
    font-size: 2.5em;
    h1 {
        color:#C70039;
        font-weight: 900;
        font-family: "Montserrat", sans-serif;
        font-weight: 900;

    }
    @media (max-width:768px) {
        font-size: 4rem;
    }
    @media (max-width:425px) {
        font-size: 1.7rem;
        h1 {
        }
    }
`
const Banner_Title: React.FC<bannerTitleProps> =  (props) => {
  return (
    <Section>
        <div className ="container">
            <Title>
                <h1>{`${props.title }`} </h1>
            </Title>
            <SubTitle>
                <TextLoop>
                        <h1>Web Designer</h1>
                        <h1>Fullstack Developer</h1>
                        <h1>UI/UX</h1>
                </TextLoop>
            </SubTitle>
        </div>
    </Section>
  );
}

export default Banner_Title;