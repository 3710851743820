import styled from "styled-components";
import mall from '../../../assets/mall.jpg'
const Card = styled.div`
    height: 300px;
    width:  450px;
    background-color: white;
    border-radius: 25px;
    margin: 0 auto;
    a {
        text-decoration: none;
        color:white;
    }
    /* background-image: url(${props => props.theme}); */
    :hover {

        :before {
	content:'';
        position: relative;
        height: 100%;

        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);

    }
    }
    .card__image {
        height: 100%;
        width: 100%;
        border-radius: 25px;
        img {
            height: 100%;
            width: 100%;
            border-radius: 25px;
            object-fit: cover;
        }
    }
    .card__title {
        background-color: #C70039;
        height: 60px;
        width: 50%;
        border-radius: 25px;
        position: relative;
        top: -40px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: ease-in-out 0.2s;
        :hover {
            cursor: pointer;
            background-color: #ee0043;
        }
    }

    @media (max-width: 1800px){
        width:  100%;
    }
    @media (max-width: 1440px){
        width:  100%;
    }
    @media (max-width: 1024px){
        width:  100%;
    }
    @media (max-width: 768px){
        width:  100%;
    }

    @media (max-width: 425px){
        .card__title {
            top: -30px;
            height: 60px;
            width: 60%;
        }
    }
`
export default Card;