import logo from './logo.svg';
import './App.css';

import Header from './components/common/header'
import Banner from './components/common/Banner';
import Banner_Title from './components/common/Banner_Title';
import Main from './components/common/Main';
import Particles from './components/common/Particles/particles';
import Test from './components/common/testgrid'
const style = {
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
}

function App() {
  return (
    <div style={style}>
      <Banner></Banner>
      <Banner_Title
              title = "Hi, I´m Joseph"
              sub_title = "Web Developer">
      </Banner_Title>
      <Header></Header>
      <Main></Main>

       {/* <Test></Test> */}

    </div>
  );
}

export default App;
