import Card_Work from '../Works/works';
import mall from '../../../assets/mallpage.jpg'
import apolo from '../../../assets/apolo.jpg'
import subasta from '../../../assets/subasta.jpg'
import subCliente from '../../../assets/mauction.jpg'
import pagos from '../../../assets/mainpago.jpg'
import mallDigital from '../../../assets/mall.jpg'
import Section from './styles'

const List_works = () => {
    return (
        <Section>
                <Card_Work title="e-commerce" image = {mall} url= "https://store.meetlab.com.ve/home"></Card_Work>
                <Card_Work title="Billing Software" image = {apolo} url="https://apolo.meetlab.com.ve/"></Card_Work>
                <Card_Work title="Auction Software" image = {subCliente} url="https://subasta.meetlab.com.ve/"></Card_Work>
                <Card_Work title="Admin Auction" image = {subasta} url ="https://adminsubasta.meetlab.com.ve/login"></Card_Work>
                <Card_Work title="e-pay" image = {pagos} url ="https://epago.meetlab.com.ve/"></Card_Work>
                <Card_Work title="tuMall Digital" image = {mallDigital} url="https://mall.meetlab.com.ve/"></Card_Work>
        </Section>
    )

}
export default List_works;