
import foto from '../../../assets/card_1.jpg'
import Card from './styles_works'
export interface CardProps {
    image: string;
    title: string;
    url?: string
}

const Card_Work: React.FC<CardProps> =  (props) => {
    return (
        <Card>
            <div className="card__image">
               <img src={props.image} alt="" />
            </div>
            <a href={props.url} target="_blank">
                <div className ="card__title">
                    <h3>{props.title}</h3>
                </div>
            </a>
        </Card>
    )
}

export default Card_Work;