import styled from "styled-components";

const Section = styled.section`
        display: grid;
        //grid-template-columns: repeat(auto-fit, minmax(min(100%,25rem), 1fr));

        //grid-template-columns: repeat(auto-fit, minmax(min(100%,25rem), 2fr));
        grid-template-columns: 1fr 1fr 1fr;
        gap: 70px;
        @media (max-width: 1024px){
            padding: 0px;
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 768px){
            padding: 0px;
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 425px){
            padding: 0px;
            grid-template-columns: 1fr;
        }
`

export default Section;