import styled from 'styled-components';
export const Card = styled.section`
    margin: 0 auto;
    background-color: #292929;
    transition: ease-in-out  0.3s;
    border-radius: 25px;
    padding:30px;
    font-size: 1.5em;
    min-height: 550px;
    width: 350px;
    &:hover{
        cursor: pointer;
        background-color: #353535;
    }
    & .card__top {
        display:flex;
        font-weight: 100;
        margin-bottom: 10px;
        .card__top_logo {
            height:50px;
            width:50px;
            font-size: 1.5em;
            margin-right:20px;
        }
    }
    & .card__boddy {
        font-weight: lighter;
        line-height : 40px;
        font-size: 1em;
    }
    @media (max-width: 1800px){
        width:  100%;
    }
    @media (max-width: 1440px){
        width: 100%;
        grid-template-columns: 1fr;
        min-height: 400px;
        padding:20px;
        font-size: 1.4em;
        .card__top_logo {
            display:none;
        }
        .card__boddy {
            font-weight: lighter;
            line-height : 30px;
            font-size: .8em;
        }
    }
    @media (max-width: 1024px){
        width: 100%;
        grid-template-columns: 1fr;
        min-height: 400px;
        padding:20px;
        font-size: 1.4em;
        .card__top_logo {
            display:none;
        }
        .card__boddy {
            font-weight: lighter;
            line-height : 30px;
            font-size: .8em;
        }
    }
    @media (max-width: 768px){
        width: 100%;
        grid-template-columns: 1fr;
        min-height: 400px;
        padding:20px;
        font-size: 1.4em;
        .card__top_logo {
            display:none;
        }
        .card__boddy {
            font-weight: lighter;
            line-height : 30px;
            font-size: .8em;
        }
    }

    @media (max-width: 425px){
        width: 100%;
        grid-template-columns: 1fr;
        min-height: 400px;
        padding:20px;
        font-size: 1.4em;
        .card__top_logo {
            display:none;
        }
        .card__boddy {
            font-weight: lighter;
            line-height : 30px;
            font-size: .8em;
        }
    }

`