import Main_title from './Title/Main_Title';
import imgPortada from '../../assets/foto.jpg';
import styled from 'styled-components';
import List_Skills from './List_skills/list_skills';
import List_Works from './List_works/list_works';
import Contact from './Contact/contact';
import { Fade } from "react-awesome-reveal";

import ButtonUP from './ButtonUP';

window.onscroll = function() {
    var y = window.scrollY;
};
const Section = styled.section`
    background-color: #191919;
    color: white;
    display:flex;
    flex-direction: column;

    .container {
        margin: 0 auto;
        width: 80vw;
    }
    .mainSection {
        padding: 90px;
        .mainSection__container {
            padding: 40px;
            display: grid;
            //grid-template-columns: repeat(4, 1fr);
            //grid-template-columns: repeat(3, 1fr);
            //grid-template-columns: repeat(auto-fit, minmax(min(100%,25rem), 2fr));
            grid-template-columns: 2fr 1fr;
            gap: 40px;
        }
        .mainSection__text {
            padding: 20px;
            font-size: 1.5em;
            color:#CDCDCD;
            line-height: 2em;
        }
        .mainSection__foto {
            img {
                width:100%;
            }
        }
    }
    .cardSection {
        padding: 40px;
        background-color:#101010;
        min-height: 850px;
    }
    .cardWorksSection {
        padding: 40px;
        min-height: 100vh;
    }
    .sectionContact {
        margin-top: 50px;
        padding: 40px;
        background-color:#101010;
        //background-color:red;
        min-height: 250px;
    }
    @media (max-width: 1440px){
        .container {
            width: 100%;
        }
        .mainSection {
            .mainSection__text {
                line-height: 1.5em;
                font-size: 1.2em;
            }
            .mainSection__foto {
                display:flex;
                img {
                    width:100%;
                }
            }
        }
    };
    @media (max-width: 1024px){
        .container {
            width: 100%;
        }
        .mainSection {
            .mainSection__text {
                line-height: 1.5em;
                font-size: 1.2em;
            }
            .mainSection__foto {
                align-items: center;
                display:flex;
                img {
                    width:100%;
                    height:350px;
                }
            }
        }
    };
    @media (max-width: 768px){
        .container {
            width: 100%;
        }
        .mainSection {
            padding: 20px;
            .mainSection__container {;
                padding: 20px;
                grid-template-columns: 1fr;
            }
            .mainSection__text {
                line-height: 1.5em;
                font-size: 1.4em;
            }
            .mainSection__foto {
                display:flex;
                justify-content:center;
                align-items:center;
                img {
                    width:70%;
                    height:60%;
                }
            }
        }
        .cardSection {
            padding: 30px;
        }
        .cardWorksSection {
            padding: 30px;
        }
        .sectionContact {
            padding: 30px;
        }
    };
    @media (max-width: 425px){
        .container {
            width: 100%;
        }
        padding:0px;
        .mainSection {
            .mainSection__container {;
                padding: 10px;
            }
            .mainSection__text {
                padding: 10px;
                line-height: 1.5em;
                font-size: 1.4em;
            }
            .mainSection__foto {
                img {
                    width:100%;
                    height:100%;
                }
            }
        }
        .cardSection {
            padding: 10px;
        }
        .cardWorksSection {
            padding: 10px;
        }
        .sectionContact {
            padding: 10px;
        }
    };
    // CARD SECTION //
    .cardWorksSection {
        padding-bottom:50px;
    }
`

const Main = () => {
    return (
        <Section>
            <section id="about" className = "mainSection">
                <Main_title title = "ABOUT ME"></Main_title>
                <div className="mainSection__container">
                    <div className = "mainSection__text">
                        <p>I am a professional in the software development area, with 15 year of experience in the management of requirements for private companies and government entities, able to work under pressure, graphic design enthusiastic and UX/UI techniques. </p>
                        <br />
                        <p>I enjoy being challenged and be part of projects that require to work out of my set of knowledge and comfort zone, keep learning development techniques is very important to me. This is how I have acquired for more than 15 years, experience in different areas, helping the growth of countless projects.
                        I am Highly skilled in software design, testing, and development. I have a deep understanding of data structure, algorithms, and development best practices,
                        </p>
                        <p></p>
                    </div>
                    <div className = "mainSection__foto">
                        <img  src={imgPortada} alt="" />
                    </div>
                </div>
            </section>
            <section id ="skills" className = "cardSection">
                    <Main_title title = "SKILLS"></Main_title>
                    <div className="container">
                    <Fade>
                        <List_Skills></List_Skills>
                    </Fade>
                    </div>
            </section>

            <section id="portfolio" className = "cardWorksSection">
                    <Main_title title = "WORKS"></Main_title>
                    <Fade>
                        <div className="container">
                            <List_Works></List_Works>
                        </div>
                    </Fade>
            </section>

             <section id="contact" className = "sectionContact">
                        <Main_title title = "CONTACT"></Main_title>
                        <div className="container">
                            <Contact></Contact>
                        </div>
            </section>
            <ButtonUP/>

        </Section>
    )
}

export default Main;