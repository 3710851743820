import styled from "styled-components";
import portada from '../../assets/portada.jpg'

import Particles from './Particles/particles';
const Section = styled.section`
    background-color:red;
    height:100vh;
    //background-image:url("./public/portada.jpg");
    background-image:url(${portada});
    //background-repeat: no-repeat;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(253,187,45,1) 100%);
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(129,102,17,1) 32%, rgba(249,196,33,1) 100%);

;
    display:flex;
    justify-content:center;
    align-items:center;
`
const  Banner = () => {
    return (
        <Section className="fondo">
            <Particles></Particles>
        </Section>
    )
}

export default Banner;