import Card_Skill from "../Skills";
import Lista_Skills from "./styles";
import { DiJsBadge } from 'react-icons/di';
import { FiFigma } from 'react-icons/fi';
import { DiDotnet } from 'react-icons/di';
import { SiAdobeillustrator } from 'react-icons/si';
const List_Skills =  () => {
    return (
        <Lista_Skills>
            <Card_Skill
                    title  = "Web Developer"
                    logo   = {(props:any) => <DiJsBadge />}
                    description = "If you need a simple page or a complete web application, you can be sure that it will have everything that you and your clients needs.">
                </Card_Skill>
            <Card_Skill
                title  = "Desktop Apps"
                logo   = {(props:any) => <DiDotnet />}
                description = "Get a great experience with a desktop app, nice, fast and secure">
            </Card_Skill>
            <Card_Skill
                title  = "UX/UI Desing"
                logo   = {(props:any) => <FiFigma />}
                description = "I love create a synergy between the elements and the design, making them not only look good but also have a purpose.">
            </Card_Skill>
            <Card_Skill
                title  = "Graphic Design"
                logo   = {(props:any) => <SiAdobeillustrator />}
                description = "I combine my development skills with my passion for design, and put everything together to get the best designs with a great look and feel.">
            </Card_Skill>
        </Lista_Skills>
    )
}

export default List_Skills;