import Section from './style_title';
export interface mainTitleProps {
    title: string;
}


const Main_Title: React.FC<mainTitleProps> = (props) => {
    return (
        <Section>
            <h1>{props.title}</h1>
        </Section>
    );
}

export default Main_Title;