import styled from 'styled-components';
const List_Skills = styled.div`
        display: grid;
        //grid-template-columns: repeat(auto-fit, minmax(min(100%,25rem), 1fr));

        grid-template-columns: repeat(auto-fit, minmax(min(100%,25rem), 2fr));
        //grid-template-columns: 1fr 1fr 1fr;
        gap: 80px;
        padding: 10px;
        @media (max-width: 1024px){
            padding: 10px;
            section:first-child {
                grid-column-start: 1;
                grid-column-end: 1;
            }
        }
        @media (max-width: 1024px){
            padding: 10px;
            section:first-child {
                grid-column-start: 1;
                grid-column-end: 1;
            }
        }
        @media (max-width: 768px){
            padding: 10px;
            section:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
        @media (max-width: 425px){
            padding: 10px;
            section:first-child {
                grid-column-start: 1;
                grid-column-end: 1;
            }
        }
`
export default List_Skills;