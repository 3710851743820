import React from 'react';

const Particles = () => {
    return (
        <div className="containerParticles">
                    <Repeat numTimes={150}>
                        {(index:number) => <div className= "circle-container" key={index}><div className= "circle" ></div></div>}
                    </Repeat>
        </div>
    )
}

function Repeat(props:any) {
    let items = [];
    for (let i = 0; i < props.numTimes; i++) {
      items.push(props.children(i));
    }
    return <div>{items}</div>;
  }

export default Particles