import styled from "styled-components";
import { DiJsBadge } from 'react-icons/di';
import {Card} from './styles'
import React from 'react';

type IconType = (props: any) => JSX.Element;
export interface Card_SkillProps {
    logo: IconType;
    title: string;
    description: string;

}


const Card_Skill: React.FC<Card_SkillProps> = (props) => {
    return (
        <Card>
            <div className = "card__top">
                <div className ="card__top_logo"> {React.createElement(props.logo)}</div>
                <h1 className ="card__top_titulo">{props.title}</h1>
            </div>
            <div className = "card__boddy">
                    {props.description}
            </div>
            <div className = "skills">

            </div>
        </Card>
    )
}

export default Card_Skill;